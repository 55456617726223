import React, { useEffect, useState } from "react";
import { apiUrl } from "./LoginSignup";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import LineChart from "../components/LineChart";
import Loader from "./Loader";

const userSession = JSON.parse(localStorage.getItem("userSession"));

const DashboardContent = () => {
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [recentBookings, setRecentBookings] = useState([]);
  const [todayRevenue, setTodayRevenue] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const [Token, setToken] = useState("");

  const theme = useTheme();

  const toggleDarkMode = () => setDarkMode((prevMode) => !prevMode);

  const appTheme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: darkMode ? "#90caf9" : "#1976d2",
      },
      secondary: {
        main: darkMode ? "#f48fb1" : "#d32f2f",
      },
    },
  });

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem("userSession"));
    if (userSession && userSession.user_id) {
      setUserRole(userSession.user_role);
      setToken(userSession.token);
      fetchTotalBookings(userSession);
      fetchTotalUsers(userSession);
      fetchRecentBookings(userSession);
    } else {
      console.error("User session not found.");
      setLoading(false);
    }
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const fetchTotalBookings = (userSession) => {
    setLoading(true);

    const url = ["admin", "dev", "senior admin"].includes(userSession.user_role)
      ? `${apiUrl}/booking/all`
      : `${apiUrl}/user/bookings/${userSession.user_id}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userSession.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        const bookingsData = data.Allbookings || data;
        const totalBookingCount = bookingsData.length || 0;
        const today = getTodayDate();
        const currentMonth = new Date().getMonth(); // Get the current month (0-based index)
        const currentYear = new Date().getFullYear(); // Get the current year

        // Calculate the current month's total revenue
        const currentMonthRevenueAmount = bookingsData
          .filter((booking) => {
            const bookingDate = new Date(
              booking.term_1_payment_date
                ? booking.term_1_payment_date
                : booking.term_2_payment_date
                ? booking.term_2_payment_date
                : booking.term_3_payment_date
            );
            return (
              bookingDate.getMonth() === currentMonth &&
              bookingDate.getFullYear() === currentYear
            );
          })
          .reduce(
            (acc, booking) =>
              acc + booking.term_1 + booking.term_2 + booking.term_3,
            0
          );
        const todayRevenueAmount = bookingsData
          .filter((booking) => booking.createdAt.split("T")[0] === today)
          .reduce(
            (acc, booking) =>
              acc + booking.term_1 + booking.term_2 + booking.term_3,
            0
          );

        setTotalBookings(totalBookingCount);
        setTotalRevenue(currentMonthRevenueAmount);
        setTodayRevenue(todayRevenueAmount);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching total bookings:", error);
        setLoading(false);
      });
  };

  const fetchTotalUsers = (userSession) => {
    fetch(`${apiUrl}/user/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userSession.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch users");
        return response.json();
      })
      .then((data) => setTotalUsers(data.Users.length))
      .catch((error) => console.error("Error fetching total users:", error));
  };

  const fetchRecentBookings = (userSession) => {
    setLoading(true);

    const url = ["admin", "dev", "senior admin"].includes(userSession.user_role)
      ? `${apiUrl}/booking/all`
      : `${apiUrl}/user/bookings/${userSession.user_id}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${userSession.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch recent bookings");
        return response.json();
      })
      .then((data) => {
        const bookingsData = data.Allbookings || data;
        const recentBookingsData = bookingsData
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 7);

        setRecentBookings(recentBookingsData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching recent bookings:", error);
        setLoading(false);
      });
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Ensures the container takes the full height of the viewport
        }}
      >
        <Loader />
      </div>
    );

  return (
    <ThemeProvider theme={appTheme}>
      <Box sx={{ p: 2 }}>
        {/* Dark/Light Theme Toggle */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            {darkMode ? "Dark Mode" : "Light Mode"}
          </Typography>
          <Switch
            checked={darkMode}
            onChange={toggleDarkMode}
            inputProps={{ "aria-label": "theme toggle" }}
          />
          <IconButton onClick={toggleDarkMode} color="inherit">
            {darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Box>

        {/* Stats Cards */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Bookings</Typography>
                <Typography variant="h4">{totalBookings}</Typography>
                <Typography>Your Total Bookings</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Total Users</Typography>
                <Typography variant="h4">{totalUsers}</Typography>
                <Typography>CRM users this month</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">
                  {" "}
                  Revenue{" "}
                  {new Date().toLocaleString("default", { month: "long" })}
                </Typography>
                <Typography variant="h4">
                  {totalRevenue.toLocaleString()} INR
                </Typography>
                <Typography>Total Revenue</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Today's Revenue</Typography>
                <Typography variant="h4">
                  {todayRevenue.toLocaleString()} INR
                </Typography>
                <Typography>Revenue from Today's Bookings</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Charts */}
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <LineChart />
                <Typography variant="h6">Website Views</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <LineChart />
                <Typography variant="h6">Daily Sales</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <LineChart />
                <Typography variant="h6">Completed Tasks</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Recent Bookings */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Recent Bookings</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>BDM Name</TableCell>
                  <TableCell>Booking Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentBookings.map((booking) => (
                  <TableRow key={booking._id}>
                    <TableCell>{booking.company_name}</TableCell>
                    <TableCell>{booking.bdm}</TableCell>
                    <TableCell>
                      {new Date(booking.createdAt).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DashboardContent;
